@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    @import './base';
    @import './layout';
    @import './custom-classes.css';
    @import './table.css';
}

@import './modified-components.css';


