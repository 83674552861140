@import '~react-phone-input-2/lib/style.css';
@import "react-loading-skeleton/dist/skeleton.css";

/* React Skeleton */
.react-loading-skeleton.rounded-none {
    @apply rounded-none !important;
}

.react-loading-skeleton.rounded-lg {
    @apply rounded-lg !important;
}

.react-loading-skeleton.rounded-full {
    @apply rounded-full !important;
}

/* React Phone Input CSS override */
.react-tel-input {
    @apply font-sans;
}

.react-tel-input .form-control {
    background: #FBFBFB !important;
    border: 1px solid #F0F6FF;
    border-radius: 6px !important;
    @apply h-10 w-full bg-white-4 text-primary font-normal text-sm pl-14 focus:border-1.5 focus:outline-none;
}

.react-tel-input .form-control:read-only {
    background: #FBFBFB;
    border: 0.6px solid #E3EEFF;
    border-radius: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #A3AEC0;

    @apply w-full h-10 focus:outline-none focus:border-1.5;
}

.react-tel-input .form-control.errors {
    @apply border-error-1 !important;
}

.react-tel-input .form-control.payment.errors {
    @apply border-error-1 !important;
}

.react-tel-input .form-control.payment {
    background: rgba(255, 255, 255, 0.16) !important;
    border: 0.5px solid rgba(255, 255, 255, 0.26) !important;
    backdrop-filter: blur(17.5px);
    border-radius: 5px !important;
    letter-spacing: 1.2px !important;
    height: 50px;

    @apply text-white text-lg font-medium pl-24;
}


.react-tel-input .flag-dropdown {
    background: #FBFBFB !important;
    @apply border-gray-8 border-l-0 border-b-0 border-t-0 my-2 bg-white ml-1;
}

.react-tel-input .payment-button {
    background: rgba(255, 255, 255, 0.45) !important;
    backdrop-filter: blur(2px);
    border-radius: 6px !important;
    width: 63px;
    margin-left: 1rem !important;

    @apply text-white my-2 bg-white/45 border;
}

.react-tel-input .flag-dropdown.payment-button {
    background: rgba(255, 255, 255, 0.45) !important;
    border-color: transparent !important;
}

.react-tel-input .flag-dropdown.payment-button.open:hover {
    background: rgba(255, 255, 255, 0.45);
}

.react-tel-input .flag-dropdown.payment-button.open .selected-flag {
    background: transparent !important;
    border-radius: 0px;
}

.react-tel-input .flag-dropdown.payment-button .selected-flag {
    background: transparent !important;
    @apply mr-0;
}

.react-tel-input .flag-dropdown.payment-button .selected-flag .arrow {
    left: 40px;
    border-top: 4px solid #fff;
}

.react-tel-input .flag-dropdown.payment-button .selected-flag .arrow.up {
    border-top: none;
    border-bottom: 4px solid #fff;
}

.react-tel-input .flag-dropdown .search,
.react-tel-input .flag-dropdown .search-box {
    @apply border-none font-sans capitalize text-primary/40 font-normal text-xs;
}

.react-tel-input .flag-dropdown .selected-flag {
    background: #FBFBFB !important;
    @apply mr-1;
}

.react-tel-input .country-list .country.highlight {
    background: #F0F7FF;
    @apply text-primary;
}

.react-tel-input .country-list {
    border: 0.5px solid #B6DAFF;
    border-radius: 5px;
    @apply float-left text-left bg-white shadow-none;
}

.react-tel-input .country-list .country {
    @apply font-normal text-[13px] text-gray-10;
}

/* React Toastify CSS override */
.Toastify__toast-container {
    @apply text-center w-full sm:w-auto !important
}