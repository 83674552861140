.top-nav {
    box-shadow: 0px 4px 4px rgba(181, 216, 255, 0.17);
    height: 3.7rem;
    @apply pt-4 pb-2 sticky top-0 left-0 right-0 z-30 bg-white;

}

.logo-img {
    width: 89px;
    height: 27.99px;
}

.top-margin {
    @apply ml-4 md:ml-8 mr-4 md:mr-16 lg:mr-24 xl:mr-32 2xl:mr-40;
}

.container-box {
    @apply container mx-auto relative;
}

.left-nav{
    @apply flex-none;
}

.left-nav .page-overlay {
    /* top: 3.7rem; */
    @apply fixed hidden bottom-0 right-0 top-0 left-0 bg-primary/10 z-50;
}

.left-nav.show{
    @apply block z-100 ;
}

.left-nav.show .left-cont{
    width: 12rem;
    transition: margin-left .5s ease;
    @apply block top-0 ; 
}

.left-nav.show.slide-in .left-cont{
    @apply ml-0 left-0;
}

.left-nav.show.slide-in .page-overlay {
    @apply block lg:hidden;
}

.navbar{
    @apply mt-20;
}

.navbar li a {
    @apply w-full inline-block pl-5 xl:pl-10 py-3 my-1 relative font-medium hover:text-primary;
}

.navbar li a svg:first-child {
    @apply inline-block stroke-current text-primary mr-4 md:mr-0 lg:mr-4 xl:mr-8;
}

.navbar li a>span {
    @apply text-sm font-450 inline-block md:hidden lg:inline-block;
}

.navbar li a:hover {
    @apply bg-gray-1;
}

.navbar li:last-child a:hover :is(svg, span) {
    @apply text-error-1;
}

.navbar li a.active svg {
    @apply text-primary-6;
}

.navbar li a.active {
    @apply bg-gray-1 border-l-3 border-primary-4 text-primary-6;
}

.page {
    min-height: calc(100vh - 3.7rem);
    @apply flex;
}

.no-auth-child {
    background: #FFFFFF;
    border: 1px solid #D0E0FC;
    box-shadow: -12px 15px 16px 1px rgba(163, 174, 192, 0.15);
    border-radius: 10px;

    @apply block;

}

.no-auth {
    @apply my-auto mx-5 sm:mx-auto;
}

@media (max-width: 767px) {
    .no-auth {
        @apply w-full;
    }
}

@media (min-width: 768px) {
    .no-auth {
        min-width: 428px;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .no-auth {
        max-width: 436px;
    }
}

.no-auth-sub-child {
    min-height: 306px;
    @apply p-5 md:p-10 flex items-center;
}
.auth-child {
    border: 0.8px solid #B6DAFF;
    @apply p-5 pb-10 bg-white rounded-md mx-4 sm:mx-0;
}

@media (min-width: 1536px) {
    .auth-child {
        min-height: calc(100vh - 18rem);
    }
}

@media (max-width: 1535px) {
    .auth-child {
        min-height: calc(100vh - 14rem);
    }
}

@media (max-width: 1279px) {
    .auth-child {
        min-height: calc(100vh - 10rem);
    }
}

@media (max-width: 1023px) {
    .auth-child {
        min-height: calc(100vh - 6rem);
    }
}

@media (max-width: 767px) {
    .auth-child {
        min-height: calc(100vh - 3.7rem);
    }
}

.left-cont {
    background-color: #F6F6F6;
    @apply fixed h-screen z-50 border-l border-primary-3 -ml-64;
}

.left-responsive {
    @apply block md:ml-0;
}

@media (min-width: 1536px) {
    .left-responsive {
        width: 18rem;
    }
}

@media (max-width: 1535px) {
    .left-responsive {
        width: 14rem;
    }
}

@media (max-width: 1279px) {
    .left-responsive {
        width: 10rem;
    }
}

@media (max-width: 1023px) {
    .left-responsive {
        width: 6rem
    }
}

@media (max-width: 767px) {
    .left-responsive {
        @apply hidden w-0;
    }
}

.main-content {
    @apply flex-1 w-full mt-4 sm:mt-8 sm:mb-8 md:mt-16 mb-4 md:mb-12 lg:mb-20 xl:mb-28 2xl:mb-36 mr-0 md:mr-12 lg:mr-20 xl:mr-28 2xl:mr-36;
}

@media (max-width: 639px) {
    .cursor-nav {
        @apply cursor-pointer !important;
    }
}

@media (min-width: 767px) {
    .cursor-nav {
        cursor: none !important;
        pointer-events: none !important;
    }
}

@media (min-width: 1536px) {
    .content-width {
        width: calc(100vw - 32rem);

    }
}

@media (max-width: 1535px) {
    .content-width {
        width: calc(100vw - 22rem);

    }
}

@media (max-width: 1279px) {
    .content-width {
        width: calc(100vw - 12rem);
    }
}

@media (max-width: 1023px) {
    .content-width {
        width: calc(100vw - 9rem);
    }
}

@media (max-width: 767px) {
    .content-width {
        width: 100%;
    }
}