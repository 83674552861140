.form-label {
  @apply font-medium text-sm text-primary;
}

.btn-blue {
  background: linear-gradient(75.08deg, #2269b3 16.08%, #3fa6d9 105.3%);
  border-radius: 5px;
  @apply p-3 text-white text-center font-medium text-sm;
}

.btn-blue:disabled {
  background: #f7f7f7;
}

.btn-white {
  background: #fbfbfb;
  border: 0.8px solid #687b9b;
  border-radius: 6px;
  color: #687b9b;
  @apply p-3 text-center font-semibold text-sm;
}

.btn-white:disabled {
  background: #f7f7f7;
}

.btn-gray {
  background: #fbfbfb;
  border: 0.8px solid #a8b3c4;
  border-radius: 15px;
  color: #636872;
  @apply p-3 text-center font-normal text-xs;
}

.btn-gray:disabled {
  background: #f7f7f7;
}

.btn-yellow {
  background: #efa040;
  border: 0.8px solid #efa040;
  border-radius: 5px;
  @apply p-3 text-white text-center font-medium text-sm;
}

.btn-yellow:disabled {
  background: #f7f7f7;
}

.btn-skeleton {
  border-radius: 6px;
  @apply p-3;
}

.form-input {
  background: #fbfbfb;
  border: 1px solid #f0f6ff;
  border-radius: 6px;

  @apply w-full h-10 pl-3 text-primary font-normal text-sm disabled:bg-gray-3 focus:outline-none focus:border-1.5;
}

.form-input.errors {
  @apply border-error-1;
}

.error-message {
  @apply text-left text-error-1 text-xxs font-normal ml-3 mt-0.5 mb-0 mr-0 p-0;
}

.search-input {
  background: #ffffff;
  border: 0.8px solid #b6daff;
  border-radius: 5px;
  height: 40px;

  @apply w-full h-10 pl-3 text-primary font-normal text-sm focus:outline-none focus:border-1.5 placeholder-white-3 placeholder-shown:pl-3 placeholder-shown:font-normal placeholder-shown:text-sm;
}

.drop-down-icon {
  background-image: url(./images/select.svg);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  background-repeat: no-repeat;
  background-position: right 0.8rem center;
  background-size: 16px 16px;
}

.drop-down-icon-primary {
  background-image: url(./images/select-primary.svg);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  background-repeat: no-repeat;
  background-position: right 0.8rem center;
  background-size: 8px 7px;
}

.drop-down-icon-white {
  background-image: url(./images/select-white.svg);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  background-repeat: no-repeat;
  background-position: right 0.8rem center;
  background-size: 7px 6px;
}

.select {
  background-image: url(./images/select.svg);
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  background-repeat: no-repeat;
  background-position: right 0.8rem center;
  background-size: 0.5rem 0.4rem;
  border: 0.5px solid #b6daff;
  border-radius: 5px;

  @apply appearance-none pr-10 text-gray-700 disabled:bg-gray-3 bg-white font-sans w-auto py-2 px-2 text-white-3 font-normal text-sm focus:outline-none focus:border-1.5 focus:ring-0;
}

.select-white {
  background-image: url(./images/select-white.svg);
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  background-repeat: no-repeat;
  background-position: right 0.8rem center;
  background-size: 0.5rem 0.4rem;
  border: 0.5px solid #b6daff;
  border-radius: 5px;

  @apply appearance-none pr-10 text-gray-700 disabled:bg-gray-3 bg-white font-sans w-auto py-2 px-2 text-white-3 font-normal text-sm focus:outline-none focus:border-1.5 focus:ring-0;
}

.select-option {
  @apply font-sans py-2 px-4 text-sm text-primary;
}

.popover {
  border: 0.5px solid #b6daff;
  border-radius: 5px;
  max-height: 40vh;
  @apply overflow-auto z-50 w-auto float-left py-1 list-none text-left bg-white;
}

.popover .selected {
  background: #f0f7ff;
  @apply text-primary;
}

.blue-gradient {
  background: linear-gradient(75.08deg, #2269b3 16.08%, #3fa6d9 105.3%);
}

.card {
  border: 0.8px solid #b6daff;
  box-shadow: 10px 14px 16px rgba(188, 198, 209, 0.21);
  border-radius: 10px;
  transition: box-shadow ease-in 0.3s;

  @apply block hover:border-0 cursor-pointer h-full p-4;
}

.top-border {
  @apply -mx-5 border-b-0.5 border-primary-3 pb-5;
}

.settings-navbar a {
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;

  @apply text-primary px-1 md:px-5 lg:px-10 py-3;
}

.settings-navbar a.active {
  @apply border-b-3 border-primary-4 text-primary-4;
}

.underline-on-click {
  text-decoration: none;
  /* color: blue; */
}

.underline-on-click:active,
.underline-on-click:focus {
  /* text-decoration: underline; */
  @apply border-b-3 border-primary-4 text-primary-4;
}

.copy-input {
  background: #fbfbfb;
  border: 0.6px solid #e3eeff;
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #a3aec0;

  @apply w-full h-10 pl-3 focus:outline-none focus:border-1.5;
}

.copy-input-label {
  @apply font-normal text-sm text-primary mb-2;
}

.subheading {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #cacdd6;
}

.file-input {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #959fb1;
  border: 0.8px solid #062558;
  box-shadow: 10px 14px 16px rgba(188, 198, 209, 0.21);
  border-radius: 5px;

  @apply w-full h-11 text-left pl-20 cursor-pointer focus:outline-none;
}

.file-input.errors {
  @apply border-error-1;
}

.modal {
  border: 0.8px solid #b6daff;
  box-shadow: 10px 14px 16px rgba(188, 198, 209, 0.21);
  border-radius: 10px;

  @apply bg-white pt-4 flex flex-col overflow-hidden;
}

.page-padding {
  @apply px-5 lg:px-10;
}

.payment-second-step {
  background: #ffffff;
  border: 0.8px solid #b6daff;
  box-shadow: 10px 14px 16px rgba(188, 198, 209, 0.21);
  border-radius: 10px;

  @apply h-full p-5;
}

.select-circle {
  width: 18px;
  height: 18px;
  border: 1.28571px solid #e9eef4;
  box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.13);
  background: #3fa6d9;

  @apply rounded-full flex m-4;
}

.custom-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-toggle .line {
  display: block;
  background-color: rgba(236, 25, 28, 1);
  width: 3.5rem;
  height: 2rem;
  border-radius: 9999px;
}

.custom-toggle .dot {
  position: absolute;
  left: 0.25rem;
  top: 0.25rem;
  background-color: #ffffff;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 9999px;
  transition-property: background-color, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
}

.custom-toggle input:checked ~ .dot {
  transform: translateX(100%);
}

.custom-toggle input:checked ~ .line {
  background: #61b467 !important;
}

.custom-toggle input:disabled ~ .line {
  opacity: 0.3;
}

.table-div {
  background: #fbfdff;
  border: 1px solid #fafcff;
  border-radius: 8px;
  font-style: normal;
  overflow-x: auto;

  @apply relative text-sm font-normal text-primary px-5 py-3;
}

.fees-div {
  display: flex;
  margin-top: 1rem;
  background: #ffffff;
  border: 0.534825px solid #f4f6fd;
  border-radius: 4px;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.progress-wrapper {
  display: flex;
  position: relative;
  justify-content: space-between;
}

.progress-bar {
  top: 18px;
  width: 98%;
  margin-left: 1%;
  position: absolute;
  border: 1px solid #e9eef4;
  background: #f8f8f8;
  border-radius: 5px;
  height: 6px;
}

.active-progress {
  background: linear-gradient(75.08deg, #2269b3 16.08%, #3fa6d9 105.3%);
  border-radius: 5px;
  height: 6px;
}

.progress-indicator {
  text-align: center;
  z-index: 1;
}

.indicator-icon {
  background: #f8f8f8;
  border: 1px solid #e9eef4;
  border-radius: 50%;
  padding: 10px;
  margin: auto;
  width: 38px;
}

.indicator-icon img {
  max-width: 18px;
}

.indicator-icon.active {
  background: linear-gradient(75.08deg, #2269b3 16.08%, #3fa6d9 105.3%);
  border: none;
}

.indicator-label {
  margin-top: 10px;
  font-weight: 500;
  font-size: 12px;
  color: #96a3b9;
}

.indicator-label.active {
  color: #062558;
}

.custom-dropdown {
  position: relative;
  /* display: inline-block; */
}

.dropdown-toggle {
  background-color: #fff;
  /* cursor: pointer; */
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
}

.radio-label {
  display: block;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.radio-input {
  margin-right: 0.5rem;
}
