.custom-table {
    @apply text-sm text-center table-auto;
}

@media (max-width: 640px) {

    .custom-table.min-w-max {
        min-width: max-content;
    }
}

@media (min-width: 768px) {
    .custom-table {
        @apply min-w-full;
    }
}

@media (max-width: 767px) {
    .custom-table {
        min-width: 50rem;
    }
}

.table-circle-green {
    width: 6px;
    height: 6px;
    background: #61B467;

    @apply rounded-full flex m-5;
}

.table-circle-red {
    width: 6px;
    height: 6px;

    @apply bg-error-1 rounded-full flex m-5;
}

.table-circle-yellow {
    width: 6px;
    height: 6px;
    background: #F0A446;

    @apply rounded-full flex m-5;
}

.table-circle-gray {
    width: 6px;
    height: 6px;

    @apply bg-gray-15 rounded-full flex m-5;
}

.table-circle-skeleton {
    width: 6px;
    height: 6px;

    @apply rounded-full flex m-5;
}

.border-spacing-4 {
    border-collapse: separate;
    border-spacing:0 1rem;
}