html {
    background-color: #F6F6F6;
    -webkit-tap-highlight-color: transparent;
    @apply font-sans !important
}

html a, .a {
    @apply text-primary hover:text-primary-2;
}

html a.disabled, .a.disabled {
    @apply text-gray-3 pointer-events-none;
}

html .hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

html .hide-scrollbar::-webkit-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: none;
}

html button:focus {
    @apply outline-none;
}

html .smooth-scroll {
    scroll-behavior: smooth
}

html input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
    transition: all 5000s ease-in-out 0s;
    transition-property: background-color, color;
}

/* input[data-autocompleted] {
    background-color: transparent !important;
} */

h1 {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 24px !important;
    line-height: 30px;
    color: #062558;
}

h2 {
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: #062558;
}

input:checked + div {
    background: #FBFBFB !important;
    border: 0.6px solid #29AAE3 !important;
}

input:checked + div svg {
    @apply block;
}

td.spinner {
    text-align: center !important;
    @apply pt-5;
}

.css-18zqmib{
    background-color: white !important;
}